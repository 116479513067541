body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app{
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.user-table{
  display: flex;
  flex-direction: column;
  height: 100%;

  justify-content: center;
  align-items: center;
}

.user-table-bar{
  font-size: 1.5em;
  margin: 0;
  display: inline-block;
  margin-right: 1em;
}

.user-table-header{
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.suggestion-table-bar{
  font-size: 1.5em;
  margin: 0;
  display: inline-block;
  margin-right: 1em;
}

.suggestion-table-header{
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.main-user-table{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
  background: white;
}

header {
  background: #d2edf4;
  padding: 10px 15px 5px 15px;
  position: relative;
  box-shadow: 0 3px 3px rgba(34, 25, 25, 0.4);
}


.grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
  column-gap: 50px;
  padding: 20px;
}